import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './assets/css/style.css';
import LanguageSwitcher from './components/language/LanguageSwitcher';
import { useTranslation } from 'react-i18next';

const LandingPage = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);


  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const navbar = document.querySelector('#navbar');
    const button = e.currentTarget;

    if (navbar && button) {
      navbar.classList.toggle('navbar-mobile');
      button.classList.toggle('bi-list');
      button.classList.toggle('bi-x');

    }
  };

  const { t } = useTranslation();

  return (
    <>
      <header id="header" className="fixed-top d-flex align-items-center">
        <div className="container d-flex justify-content-between align-items-center">
          <div className="logo">
            <h1><Link to="/dashboard/home">Spondylus</Link></h1>
          </div>
          <nav id="navbar" className="navbar">

            <ul>
              <li><Link className="active" target='blank' to="https://dev.fintech.spondylus.app/dashboard/home/">{t('Login')}</Link></li>
              <li><Link target='blank' to="https://dev.fintech.spondylus.app/dashboard/home/">{t('Sign Up')}</Link></li>
              <LanguageSwitcher></LanguageSwitcher>
            </ul>
            <i className="bi bi-list mobile-nav-toggle" onClick={handleClick}></i>
          </nav>
        </div>
      </header>

      <section className="hero-section" id="hero">
        <div className="wave">
          <svg width="100%" height="355px" viewBox="0 0 1920 355" xmlns="http://www.w3.org/2000/svg">
            <g fill="#FFFFFF">
              <path d="M0,439.134243 C175.04074,464.89273 327.944386,477.771974 458.710937,477.771974 C654.860765,477.771974 870.645295,442.632362 1205.9828,410.192501 C1429.54114,388.565926 1667.54687,411.092417 1920,477.771974 L1920,757 L1017.15166,757 L0,757 L0,439.134243 Z"></path>
            </g>
          </svg>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 hero-text-image">
              <div className="row">
                <div className="col-lg-8 text-center text-lg-start">
                  <h1 data-aos="fade-right">{t('Header')}</h1>
                  <p className="mb-5" data-aos="fade-right" data-aos-delay="100">{t('Header1')}</p>
                  <p data-aos="fade-right" data-aos-delay="200"><Link to="https://dev.fintech.spondylus.app/" target='blank' className="btn btn-outline-white">{t('Come on')}</Link></p>
                </div>
                <div className="col-lg-4 iphone-wrap">
                  <img src="./assets/img/phone2.png" alt="Phone 1" className="phone-1" data-aos="fade-right" />
                  <img src="./assets/img/phone1.png" alt="Phone 2" className="phone-2" data-aos="fade-right" data-aos-delay="200" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <main id="main">
        <section className="section">
          <div className="container">
            <div className="row justify-content-center text-center mb-5">
              <div className="col-md-5" data-aos="fade-up">
                <h2 className="section-heading">{t('Header2')}</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4" data-aos="fade-up">
                <div className="feature-1 text-center">
                  <div className="wrap-icon icon-1">
                    <i className="bi bi-people"></i>
                  </div>
                  <h3 className="mb-3">{t('Secure Finance')}</h3>
                  <p>{t('Secure Finance Subtitle')}</p>
                </div>
              </div>
              <div className="col-md-4" data-aos="fade-up" data-aos-delay="100">
                <div className="feature-1 text-center">
                  <div className="wrap-icon icon-1">
                    <i className="bi bi-brightness-high"></i>
                  </div>
                  <h3 className="mb-3">{t('Secure Application')}</h3>
                  <p>{t('Secure Aplication Subtitle')}</p>
                </div>
              </div>
              <div className="col-md-4" data-aos="fade-up" data-aos-delay="200">
                <div className="feature-1 text-center">
                  <div className="wrap-icon icon-1">
                    <i className="bi bi-bar-chart"></i>
                  </div>
                  <h3 className="mb-3">{t('The best companies')}</h3>
                  <p>{t('The best companies Subtitle')}</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section">
          <div className="container">
            <div className="row justify-content-center text-center mb-5" data-aos="fade">
              <div className="col-md-6 mb-5">
                <img src="/assets/img/phone3.png" alt="Phone 3" className="img-fluid" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <div className="step">
                  <span className="number">01</span>
                  <h3>{t('Login')}</h3>
                  <p>{t('Sign in securely')}</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="step">
                  <span className="number">02</span>
                  <h3>{t('Come on')}</h3>
                  <p>{t('Register your data')}</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="step">
                  <span className="number">03</span>
                  <h3>{t('Enjoy the app')}</h3>
                  <p>{t('Start investing')}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer className="footer" role="contentinfo">
        <div className="container">
          <div className="row">
            <div className="col-md-4 mb-4 mb-md-0">
              <h3>{t('About Spondylus App')}</h3>
              <p>{t('We are the first app')}</p>
              <p className="social">
                <Link to="#"><span className="bi bi-twitter"></span></Link>
                <Link to="#"><span className="bi bi-facebook"></span></Link>
                <Link to="#"><span className="bi bi-instagram"></span></Link>
                <Link to="#"><span className="bi bi-linkedin"></span></Link>
              </p>
            </div>
            <div className="col-md-7 ms-auto">
              <div className="row site-section pt-0">
                <div className="col-md-4 mb-4 mb-md-0">
                  <h3>{t('Company')}</h3>
                  <ul className="list-unstyled">
                    <li><Link to="#">{t('About us')}</Link></li>
                    <li><Link to="#">{t('Jobs')}</Link></li>
                    <li><Link to="#">{t('News')}</Link></li>
                  </ul>
                </div>
                <div className="col-md-4 mb-4 mb-md-0">
                  <h3>{t('Customers')}</h3>
                  <ul className="list-unstyled">
                    <li><Link to="#">{t('Assistance')}</Link></li>
                    <li><Link to="#">{t('Price fixing')}</Link></li>
                    <li><Link to="#">{t('Legal Information')}</Link></li>
                  </ul>
                </div>
                <div className="col-md-4 mb-4 mb-md-0">
                  <h3>{t('Downloads')}</h3>
                  <ul className="list-unstyled">
                    <li><Link to="#">App Store</Link></li>
                    <li><Link to="#">Google Play</Link></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default LandingPage;
