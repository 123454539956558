import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = () => {
  useEffect(() => {
    const handleClick = (e: Event) => {
      const target = e.target as HTMLElement;
      const navbar = document.querySelector('#navbar');
      
      if (navbar?.classList.contains('navbar-mobile')) {
        e.preventDefault();
        const dropdown = target.nextElementSibling as HTMLElement | null;
        dropdown?.classList.toggle('dropdown-active');
      }
    };

    const dropdownLinks = document.querySelectorAll('.navbar .dropdown > a');
    dropdownLinks.forEach(link => {
      link.addEventListener('click', handleClick);
    });

    return () => {
      dropdownLinks.forEach(link => {
        link.removeEventListener('click', handleClick);
      });
    };
  }, []);

  const { i18n } = useTranslation();
  const { t } = useTranslation();
 
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div>      

      <li className="dropdown">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a href="#"><span>{t('Language')}</span> <i className="bi bi-chevron-down"></i></a>
        <ul>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <li onClick={() => changeLanguage('en')}><a href="#">English</a></li>          
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <li onClick={() => changeLanguage('es')}><a href="#">Español</a></li>
        </ul>
      </li>
    </div>
  );
};

export default LanguageSwitcher;
